import React, { useState } from "react"
import {
  Button,
  Heading,
  InputFieldBlock,
  SelectFieldBlock,
  SuccessIcon,
  Text,
  TextAreaFieldBlock,
  Link,
} from "gatsby-interface"
import { Box } from "theme-ui"
import { MdArrowForward, MdAutorenew } from "react-icons/md"
import delay from "../../utils/delay"
import background from "../../assets/support-form-background.svg"
import hand from "../../assets/support-form-hand.svg"

// Categories for the What do you need help with? form field.
const categories = [
  { label: "-", value: "" },
  { label: "Builds", value: "build" },
  { label: "Preview", value: "preview" },
  { label: "Hosting integration", value: "hosting" },
  { label: "My account", value: "account" },
  { label: "Other", value: "other" },
]

const wrapperCSS = theme => ({
  backgroundImage: `url(${background})`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `50px 150px`,
  backgroundSize: `contain`,
  padding: theme.space[2],
  position: "relative",
  maxWidth: `900px`,
  margin: `0 auto`,
})

const formContainerCSS = () => ({
  maxWidth: "610px",
  background: "#FFFFFF",
  boxShadow:
    "0px 8px 24px rgba(71, 63, 79, 0.16), 0px 4px 16px rgba(46, 41, 51, 0.08)",
  borderRadius: "6px",
  padding: "35px",
  margin: "10rem auto",
  position: "relative",
})

const formBackgroundCSS = () => ({
  position: "absolute",
  zIndex: 1,
  bottom: `50px`,
  right: `-18px`,
})

const inputBoxCSS = theme => ({
  "& > div": {
    flex: 1,
  },

  [theme.mediaQueries.phablet]: {
    display: "flex",
    "> div + div": {
      marginLeft: "1rem",
    },
  },
})

const inputCSS = theme => ({
  "& > div": {
    marginBottom: theme.space[7],
  },
})

const emphasizedHeadingCss = theme => ({
  fontSize: theme.fontSizes[8],
  lineHeight: theme.lineHeights.dense,
  textAlign: `center`,
  maxWidth: `680px`,
  margin: `${theme.space[6]} auto`,
  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[8],
  },
})

const disclaimerTextCss = () => ({
  maxWidth: `680px`,
  margin: `0 auto`,
})

const submitButtonCSS = () => ({
  width: `100%`,
})

const SupportForm = () => {
  const [firstName, setfirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [category, setCategory] = useState("")
  const [site, setSite] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [submissionMessage, setSubmissionMessage] = useState("")
  const [success, setSuccess] = useState("")

  const handleSubmit = async event => {
    event.preventDefault()
    setIsLoading(true)

    const res = await fetch(`https://gatsby.zendesk.com/api/v2/requests.json`, {
      method: `POST`,
      body: JSON.stringify({
        request: {
          requester: {
            name: `${firstName} ${lastName}`,
            email: email,
          },
          subject: `${firstName} ${lastName} requests support from Gatsby!`,
          comment: {
            body: message,
          },
          custom_fields: [
            {
              id: 360053450553,
              value: category,
            },
            {
              id: 360053450893,
              value: site,
            },
          ],
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })

    const response = await res.json()

    setIsLoading(false)

    if (!response.request) {
      setSubmissionMessage(
        "There was an error processing your request. Please make sure all required fields are filled and try again!"
      )
    } else {
      setSubmissionMessage("")
      setSuccess(true)
      await delay(5000)

      // Reset the form
      setEmail("")
      setfirstName("")
      setLastName("")
      setMessage("")
      setCategory("")
      setSite("")
      setSuccess("")
    }
  }

  return (
    <section>
      <Heading as="h2" variant="EMPHASIZED" css={emphasizedHeadingCss}>
        Have a question about your Gatsby Cloud account that isn’t answered
        above? Hit us up below!
      </Heading>
      <Text css={disclaimerTextCss}>
        <p>
          For our Gatsby Cloud customers, our team is happy to further assist
          you. Please fill out the form below and one of our team members will
          respond to you. To help expedite resolution, you should include the
          following in your request:
        </p>
        <ul>
          <li>Workspace or Site name </li>
          <li>
            Link to{" "}
            <Link href="https://support.gatsbyjs.com/hc/en-us/articles/360053099173-Build-Logs">
              build logs
            </Link>{" "}
            (for build issues)
          </li>
          <li>
            Steps to reproduce the error you are encountering (if
            possible/applicable){" "}
          </li>
        </ul>
        <p>
          If your issue is not related to Gatsby Cloud and the resources listed
          above aren&apos;t what you need{" "}
          <Link href="https://support.gatsbyjs.com/hc/en-us/articles/1500012368821">
            our Gatsby Support article
          </Link>{" "}
          recommends other support channels and resources that may be helpful to
          you.
        </p>
      </Text>
      <div css={wrapperCSS}>
        <form onSubmit={event => handleSubmit(event)} css={formContainerCSS}>
          <Box css={inputBoxCSS}>
            <InputFieldBlock
              id="firstName"
              label="First Name"
              css={inputCSS}
              required
              value={firstName}
              onChange={e => setfirstName(e.target.value)}
            />
            <InputFieldBlock
              id="lastName"
              label="Last Name"
              css={inputCSS}
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
          </Box>
          <Box css={inputBoxCSS}>
            <InputFieldBlock
              id="email"
              label="Email"
              required
              value={email}
              css={inputCSS}
              onChange={e => setEmail(e.target.value)}
            />
            <InputFieldBlock
              id="cloud-site"
              label="Gatsby Cloud Site Name"
              css={inputCSS}
              onChange={e => setSite(e.target.value)}
            />
          </Box>
          <SelectFieldBlock
            id="category"
            label="What do you need help with?"
            options={categories}
            css={inputCSS}
            required
            onChange={e => setCategory(e.target.value)}
          />
          <TextAreaFieldBlock
            css={inputCSS}
            id="message"
            label="Message"
            hint="Please describe your issue in detail so we can help! If your site is on Gatsby Cloud, please provide a site name, ID or URL and clear steps to reproduce."
            required
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          <Button
            css={submitButtonCSS}
            type="submit"
            loading={isLoading}
            tone={success ? `SUCCESS` : `BRAND`}
            rightIcon={success ? <SuccessIcon /> : <MdArrowForward />}
            LoadingIcon={MdAutorenew}
            loadingLabel="Submitting"
          >
            {success ? `Success!` : `Submit`}
          </Button>
          {submissionMessage && <p>{submissionMessage}</p>}
          <img src={hand} css={formBackgroundCSS} alt="" />
        </form>
      </div>
    </section>
  )
}

export default SupportForm
